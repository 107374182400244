import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import { Icon } from './slotMachine/d';

declare global {
  interface Window {
    __ENV__: {
      APP_VERSION: string;
      APP_NAME: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      ENV: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    Howler: {
      _spriteBaseUrl: string;
    };
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IBalanceMetadata {
  type: string;
  data: {
    messageTitle: string;
    messageBody: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
  coinCode?: string;
  replayBetId?: string;
}

export interface ISettledBet {
  id: string;
  coinAmount: number;
  coinValue: number;
  lines: number[];
  slotId: string;
  round: {
    id: string;
    reelPositions: number[];
    type: 'REGULAR' | 'FREE_SPIN';
    hasNext: boolean;
    createdAt: string;
    balance: {
      currency: string;
      amount: number;
    };
    paylines: {
      lineId: number;
      winPositions: number[];
      amount: number;
    }[];
    spinResult: Icon[];
  };
  balance: {
    currency: string;
    amount: number;
  };
}

export interface LineSet {
  id: string;
  slotId: string;
  lines: number[];
  coinAmountMultiplier: number;
}
export interface ISettledBet2 {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    userBonusId: string;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      isBonus: boolean;
      bonuses: UserBonus[];
      features: {
        isFreeSpins: boolean;
        mystery: { changeTo: SlotId };
      };
    };
    createdAt: string;
    updatedAt: string;
  };
  paylines: {
    lineId: number;
    winPositions: number[];
    amount: number;
  }[];
  balance: {
    placed: {
      amount: number;
      currency: string;
      metadata?: IBalanceMetadata;
    };
    settled: {
      amount: number;
      currency: string;
      metadata?: IBalanceMetadata;
    };
  };
}

export enum EventTypes {
  UPDATE_FREE_ROUNDS_LEFT = 'UPDATE_FREE_ROUNDS_LEFT',
  COMPLETE_FREE_ROUND_BONUS = 'COMPLETE_FREE_ROUND_BONUS',
  START_FREE_ROUND_BONUS = 'START_FREE_ROUND_BONUS',
  END_FREE_ROUND_BONUS = 'END_FREE_ROUND_BONUS',
  FREE_ROUND_BONUS_EXPIRED = 'FREE_ROUND_BONUS_EXPIRED',
  OPEN_POPUP = 'OPEN_POPUP',
  CLOSE_POPUP = 'CLOSE_POPUP',
  ANTICIPATION_STARTS = 'anticipationStarts',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  FORCE_STOP_REELS = 'forceStopReels',
  HIDE_WIN_LINES = 'hideWinLines',
  HIDE_COINS = 'hideCoins',
  HANDLE_CHANGE_BET_AMOUNT = 'handleChangeBetAmount',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  REMOVE_ANIMATOR = 'removeAnimator',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  SHOW_WIN_LINES = 'showWinLines',
  SHOW_TINT = 'showTint',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  SKIP_INTRO_SCREEN = 'skipIntroScreen',
  THROW_ERROR = 'throwError',
  PROLONG_SPIN_ANIMATION = 'prolongSpinAnimation',
  POST_RENDER = 'postrender',
  SET_IS_LEFT_HAND_MODE = 'setIsLeftHandMode',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  SOUND_INITIALIZED = 'soundInitialized',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoplay',
  SET_REPLAY_BET_ID = 'setReplayBetId',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  NONE = null,
}

export enum GameMode {
  FREE_ROUND_BONUS,
}

export const bonusIds = {
  [GameMode.FREE_ROUND_BONUS]: '704806e0-0efd-4dde-8f43-7171658fd8fd',
};

export type UserBonus = {
  bonusId: string;
  id: string;
  isActive: boolean;
  currentRound: number;
  betId: string;
  rounds: number;
  reelSetId: string;
  status: BonusStatus;
  gameMode: GameMode;
  isFreeBet?: boolean;
  totalWinAmount: number;
  data: UserBonusData;
  coinAmount: number;
  coinValue: number;
  hasStarted: boolean;
};

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}
